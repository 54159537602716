// Core libraries
import { Application } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Turbo = Turbo

import AnnouncementBannerController from "./controllers/announcement_banner_controller"
import DropdownController from "./controllers/dropdown_controller"
import DismissController from "./controllers/dismiss_controller"
import PasswordToggleController from "./controllers/password_toggle_controller"
import PricingController from "./controllers/pricing_controller"
import TrackingController from "./controllers/tracking_controller"

const application = Application.start()
application.register("announcement-banner", AnnouncementBannerController)
application.register("dismiss", DismissController)
application.register("dropdown", DropdownController)
application.register("password-toggle", PasswordToggleController)
application.register("pricing", PricingController)
application.register("tracking", TrackingController)

function isScrolledIntoView(el) {
  var rect = el.getBoundingClientRect()
  var elemTop = rect.top
  var elemBottom = rect.bottom

  return (elemTop >= 0) && (elemBottom <= window.innerHeight)
}

window.addEventListener('scroll', function () {
  const comments = document.querySelectorAll('.animate')
  comments.forEach(comment => {
    if (isScrolledIntoView(comment) === true) {
      comment.classList.add('in-view')
    }
  })
})

window.addEventListener('turbo:load', () => {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const id = entry.target.getAttribute('id');
      if (entry.intersectionRatio > 0) {
        document.querySelector(`nav li a[href="/product/management#${id}"]`).parentElement.classList.add('active');
      } else {
        document.querySelector(`nav li a[href="/product/management#${id}"]`).parentElement.classList.remove('active');
      }
    });
  });

  document.querySelectorAll('.management-features section[id]').forEach((section) => {
    observer.observe(section);
  });
});

window.addEventListener('turbo:load', () => {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const id = entry.target.getAttribute('id');
      if (entry.intersectionRatio > 0) {
        document.querySelector(`nav li a[href="/glossary#${id}"]`).parentElement.classList.add('active');
      } else {
        document.querySelector(`nav li a[href="/glossary#${id}"]`).parentElement.classList.remove('active');
      }
    });
  });

  document.querySelectorAll('.glossary section[id]').forEach((section) => {
    observer.observe(section);
  });
});
