import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if ( this.context.element.dataset.trackEventName ) {
      const eventName = this.context.element.dataset.trackEventName
      const properties = this._getTrackingProperties(this.context.element)
    }
  }

  trackEvent(event) {
    console.log(event.target.dataset)
    const eventName = event.target.dataset.trackEventName
    const properties = this._getTrackingProperties(event.target)
    if ( event.target.value ) {
      Object.assign(properties, { value: event.target.value })
    }

    this._saveTrackingData(eventName, properties)
  }

  _getTrackingProperties(elem) {
    const properties = elem.dataset.trackProperties || "{}"
    return JSON.parse(properties)
  }

  _saveTrackingData(eventName, properties = {}) {
    Object.assign(properties)
    console.log(eventName)

    // Google Analytics
    window.dataLayer = window.dataLayer || []
    function gtag() { dataLayer.push(arguments) }

    gtag('js', new Date())

    // Event snippet for View Pricing Page conversion page
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-719712363/NN4tCP6ax_oBEOvgl9cC',
        'event_callback': callback
    });
    return false;

    // send event eventName and properties to server to be saved in database
  }
}
