import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "password",
    "passwordIcon",
    "passwordConfirmationIcon",
    "passwordConfirmation",
    "meter",
    "text",
    "label"
  ];

  initialize() {
    import("zxcvbn").then(zxcvbn => {
      this.zxcvbn = zxcvbn.default;
    });
  }

  connect() {
    this.toggleClass = this.data.get('class') || 'show'
    if (this.hasMeterTarget) {
      this.passwordField.addEventListener("keyup", this.keyup.bind(this))
    }
  }

  toggle() {
    this.passwordIcon.classList.toggle(this.toggleClass)
  }

  password() {
    if (!this.passwordIcon.classList.contains(this.toggleClass)) {
      this.passwordIcon.classList.toggle(this.toggleClass)
      this.labelTarget.innerHTML = "Hide";
      this.passwordField.type = "text"
      if (this.hasPasswordConfirmationTarget) {
        this.passwordConfirmationField.type = "text"
        this.passwordConfirmation.classList.toggle(this.toggleClass)
      }
    } else {
      this.passwordIcon.classList.toggle(this.toggleClass)
      this.labelTarget.innerHTML = "Show";
      this.passwordField.type = "password"
      if (this.hasPasswordConfirmationTarget) {
        this.passwordConfirmation.classList.toggle(this.toggleClass)
        this.passwordConfirmationField.type = "password"
      }
    }
  }

  keyup(e) {
    let strength = {
      0: "Weak",
      1: "Bad",
      2: "OK",
      3: "Good",
      4: "Strong"
    }

    e.preventDefault()
    let val = this.passwordField.value
    let result = this.zxcvbn(val)

    // Update the password strength meter
    this.meterTarget.value = result.score

    // Update the text indicator
    if(val !== "") {
      this.textTarget.innerHTML = "<strong>" + "Password strength: " + strength[result.score] + "</strong>" + ". " + "<span class='feedback'>" + result.feedback.warning + " " + result.feedback.suggestions + "</span"
    }
    else {
      this.textTarget.innerHTML = "Your password must be at least 5 characters, and can’t begin or end with a space."
    }
  }

  get passwordIcon() {
    return this.passwordIconTarget
  }

  get passwordConfirmation() {
    return this.passwordConfirmationIconTarget
  }

  get passwordField() {
    return this.passwordTarget
  }

  get passwordConfirmationField() {
    return this.passwordConfirmationTarget
  }
}
